/** Main constants should store here */

export enum PageName {
  Home = 'Home',
  Users = 'Users',
  Components = 'Components',
  FormFields = 'FormFields',
  FormStepper = 'FormStepper',
}

export const MainConstants = {
  platformName: 'angular',
  darkThemeOnLaunch: false,
};
